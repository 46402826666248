import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const IdeaTable = ({ ideas }) => {
  const navigate = useNavigate();

  const renderTags = (tags) => {
    try {
      const parsedTags = JSON.parse(tags);
      if (Array.isArray(parsedTags)) {
        return parsedTags.map((tag, index) => {
          const colorClass = colorMapping[tag] || defaultColors[index % defaultColors.length];
          return (
            <span key={index} className={`px-4 py-2 rounded-full shadow-sm ${colorClass}`} aria-label={`Tag: ${tag}`}>
              {tag}
            </span>
          );
        });
      }
    } catch (e) {
      // Handle single value if not an array
      const colorClass = colorMapping[tags] || defaultColors[0];
      return <span className={`px-4 py-2 rounded-full shadow-sm ${colorClass}`} aria-label={`Tag: ${tags}`}>{tags}</span>;
    }
    return null;
  };

  const columns = [
    {
      accessorKey: 'summary',
      header: 'Summary',
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      cell: info => new Date(info.getValue()).toLocaleDateString(),
    },
    {
      accessorKey: 'theme',
      header: 'Theme',
      cell: info => <div className="flex flex-wrap gap-2">{renderTags(info.getValue())}</div>,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: info => (
        <span className={`px-4 py-2 rounded-full shadow-sm ${colorMapping[info.getValue()]}`} aria-label={`Status: ${info.getValue()}`}>
          {info.getValue()}
        </span>
      ),
    },
    {
      accessorKey: 'looking_for',
      header: 'Looking For',
      cell: info => <div className="flex flex-wrap gap-2">{renderTags(info.getValue())}</div>,
    },
  ];

  const table = useReactTable({
    data: ideas,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-zinc-50 shadow-md rounded border border-gray-300">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="bg-pink-900 text-light text-xl">
              {headerGroup.headers.map(header => (
                <th key={header.id} className="px-4 py-2 border">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr 
              key={row.id} 
              onClick={() => navigate(`/idea/${row.original.id}`)} 
              className="cursor-pointer hover:bg-zinc-100 hover:border-double hover:border-2 hover:border-pink-100" 
              role="button" 
              tabIndex={0}
              onKeyPress={(e) => { if (e.key === 'Enter') navigate(`/idea/${row.original.id}`); }}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className="px-4 py-2 border text-lg">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IdeaTable;
