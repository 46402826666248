// src/pages/About.js
import React from 'react';

const faqs = [
  {
    question: 'What is Open Ideas?',
    answer: 'Open Ideas is a free resource that promotes the sharing of ideas for social good. We hope that ideas can be shared, collaborated on and maybe even funded.'
  },
  {
    question: 'Who is behind Open Ideas?',
    answer: 'Tom (which one?) from Data For Action.'
  },
  {
    question: 'What are the criteria for ideas?',
    answer: 'Your idea should have a social good purpose.'
  },
  {
    question: 'Where did this idea come from?',
    answer: (
      <span>
        I ran an experiment back in 2022 which gave someone £500 to think about or work on an idea they had. People submitted ideas and one was chosen in a blind pick. I wanted to do more, and this is version 2. You can read about the experiment{' '}
        <a href="https://tomcampbellwatson.medium.com/open-ideas-97b93729b341" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          here
        </a>{' '}
        and{' '}
        <a href="https://tomcampbellwatson.medium.com/open-ideas-what-next-44cd4d4c105e" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          here
        </a>.
      </span>
    )
  },
  {
    question: 'Will you be running an "Open Ideas Fund" again?',
    answer: 'You should read one of the ideas on the page :) Yes, there is a plan to run a fund in the future. It will be a little different, but similar concepts.'
  },
  {
    question: 'Was this inspired by Friends of the Earth Experiments?',
    answer: (
      <span>
        Yes and No. The idea and intention behind this was there before I ever knew anything about the Friends of the Earth Experiments{' '}
        <a href="https://experiments.friendsoftheearth.uk/steal-our-ideas" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          'Steal our ideas'
        </a>{' '}
        page. However I recently found it, thought it was great and was inspired to actually follow through on an Idea and build this.
      </span>
    )
  },
];

const About = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-3xl font-bold">About Open Ideas</h1>
      </div>
      <div className="mb-8 p-4 bg-white shadow-md rounded-lg">
        <p className="text-lg mb-4">
          Open Ideas is a free resource that aims to share ideas openly for social good. By sharing ideas openly, we hope to build a community of thinkers and doers who can collaborate and support each other.
        </p>
        <p className="text-lg mb-4">
          Anyone can contribute an idea. You can add as much or as little detail as you like. It can be an idea you have tested, or just something you dreamt up on a walk or a run.
        </p>
        <p className="text-lg mb-4">
          Our goal is to encourage the sharing of ideas and collaboration. So you can choose if you'd like to be contacted by others via the site. Your email will be stored securely and only used for users to contact you. You can then choose whether to pursue that contact.
        </p>
        <p className="text-lg mb-4">
          We believe that by working together, we can achieve more than we ever could alone.
        </p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
            <p className="text-gray-700">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;

