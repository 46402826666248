import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const themeOptions = [
  { value: 'Technology', label: 'Technology' },
  { value: 'Health', label: 'Health' },
  { value: 'Wellbeing', label: 'Wellbeing' },
  { value: 'Education', label: 'Education' },
  { value: 'Environment', label: 'Environment' },
  { value: 'Data', label: 'Data' },
  { value: 'Other', label: 'Other' },
  { value: 'Funding & Philanthropy', label: 'Funding & Philanthropy' },
  { value: 'Innovation', label: 'Innovation' },
  { value: 'Young People', label: 'Young People' },
  { value: 'Older People', label: 'Older People' }
];

const lookingForOptions = [
  { value: 'Funding', label: 'Funding' },
  { value: 'Collaboration', label: 'Collaboration' },
  { value: 'Just take it', label: 'Just take it' }
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0.375rem',
    borderColor: '#e2e8f0',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#cbd5e0'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'linear-gradient(to right, #1a202c, #d53f8c)',
    color: 'white',
    borderRadius: '0.375rem',
    padding: '0.25rem 0.5rem', // Increase padding for larger size
    fontSize: '1rem' // Increase font size
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
    fontSize: '1rem' // Increase font size
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    '&:hover': {
      background: '#d53f8c',
      color: 'white'
    },
    fontSize: '1rem' // Increase font size
  })
};

const IdeaForm = () => {
  const { register, handleSubmit, watch, formState: { errors }, control } = useForm();
  const navigate = useNavigate();
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showCustomTheme, setShowCustomTheme] = useState(false);

  const onSubmit = async data => {
    // Check if the user already exists
    let { data: existingUsers, error: fetchError } = await supabase
      .from('users')
      .select('id')
      .eq('email', data.email);

    if (fetchError) throw fetchError;

    let userId;

    if (existingUsers.length > 0) {
      // User exists, get the user_id
      userId = existingUsers[0].id;
    } else {
      // User does not exist, create a new user
      const { data: newUser, error: insertError } = await supabase
        .from('users')
        .insert([{ name: data.person, email: data.email }])
        .select('id')
        .single();

      if (insertError) throw insertError;

      userId = newUser.id;
    }

    // Convert Yes/No to boolean
    const contactValue = data.contact === 'yes';

    // Convert multi-select values to arrays
    const themeValues = data.theme.map(option => option.value);
    const lookingForValues = data.looking_for.map(option => option.value);

    // Create the idea and link it to the user
    const { error: ideaError } = await supabase.from('ideas').insert([
      {
        summary: data.summary,
        details: data.details,
        theme: themeValues,
        status: data.status,
        looking_for: lookingForValues,
        organisation: data.organisation,
        problem: data.problem,
        challenge: data.challenge,
        benefits: data.benefits,
        person: data.person,
        how: data.how,
        stopping: data.stopping,
        contact: contactValue,
        other_theme: data.custom_theme || null, // Handle the custom theme
        user_id: userId,  // Link the idea to the user
      },
    ]);

    if (ideaError) {
      toast.error('There was an error submitting your idea. Please try again.');
      throw ideaError;
    }

    toast.success('Idea submitted successfully!');
    navigate('/');
  };

  const theme = watch('theme', '');

  // Watch for changes in the theme and toggle custom theme input
  const handleThemeChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'Other')) {
      setShowCustomTheme(true);
    } else {
      setShowCustomTheme(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-4xl mx-auto p-4 bg-light shadow-md rounded border border-gray-300">
      <div className="">
      <h1 className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold py-4 pl-4 shadow-md">Submit an idea</h1>
        <label className="block text-gray-700 text-lg font-bold mb-2 pt-8">Idea title</label>
        <input {...register('summary', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.summary && <span className="text-red-500 text-xs italic">A title is required.</span>}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">What is your idea?</label>
        <textarea {...register('details', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.details && <span className="text-red-500 text-xs italic">Please give some details about your idea.</span>}
      </div>

      <button
        type="button"
        onClick={() => setShowMoreDetails(!showMoreDetails)}
        className="bg-gradient-to-r from-gray-900 to-pink-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition mb-4"
      >
        {showMoreDetails ? 'Hide more details' : 'Add more details'}
      </button>

      {showMoreDetails && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">What is the problem your idea relates to?</label>
            <textarea {...register('problem')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">What challenge does the idea address?</label>
            <textarea {...register('challenge')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">What are the potential benefits of this idea?</label>
            <textarea {...register('benefits')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">How could this work?</label>
            <textarea {...register('how')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2">What is stopping you from turning idea into action?</label>
            <textarea {...register('stopping')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
          </div>
        </>
      )}

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Theme</label>
        <Controller
          name="theme"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              isMulti
              options={themeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={customStyles}
              onChange={(selectedOptions) => {
                field.onChange(selectedOptions);
                handleThemeChange(selectedOptions);
              }}
            />
          )}
        />
        {errors.theme && <span className="text-red-500 text-xs italic">Theme is required.</span>}
        {showCustomTheme && (
          <input {...register('custom_theme')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary mt-2" placeholder="Add your theme here if none of the others match" />
        )}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Status</label>
        <select {...register('status', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="Just an idea">Just an idea</option>
          <option value="Minimally tested">Minimally tested</option>
          <option value="Prototyped">Prototyped</option>
        </select>
        {errors.status && <span className="text-red-500 text-xs italic">Status is required.</span>}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Looking For</label>
        <Controller
          name="looking_for"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              isMulti
              options={lookingForOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={customStyles}
            />
          )}
        />
        {errors.looking_for && <span className="text-red-500 text-xs italic">Looking For is required.</span>}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Person</label>
        <input {...register('person')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Organisation (if applicable)</label>
        <input {...register('organisation')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Can others contact you about your idea via the site? This is unrelated to the Open Ideas Fund. If yes you'll need to add an email</label>
        <select {...register('contact', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        {errors.contact && <span className="text-red-500 text-xs italic">Contact preference is required.</span>}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Email Contact</label>
        <input {...register('email', { required: false })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.email && <span className="text-red-500 text-xs italic">Email is required.</span>}
      </div>

      <div className="flex items-center justify-between">
        <button type="submit" className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold hover:bg-orange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition">
          Submit Idea
        </button>
      </div>
    </form>
  );
};

export default IdeaForm;
