import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FeedbackForm = ({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const emailData = {
      to: 'hello@good-ship.co.uk',
      subject: 'Feedback Submission',
      templateId: 'd-4615467b262a4e29bd646456f976f3cb', // Your SendGrid feedback template ID
      dynamicTemplateData: {
        name: data.name,
        reason: data.reason,
        message: data.message,
        email: data.email,
      },
    };

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error('Failed to send feedback.');
      }

      toast.success('Feedback submitted successfully!');
      onClose();
    } catch (error) {
      console.error('Error sending feedback:', error);
      toast.error('There was an error sending your feedback. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-4xl mx-auto p-4 bg-light shadow-md rounded border border-gray-300">
      <h2 className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold py-4 pl-4 shadow-md">Submit Feedback</h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Name</label>
        <input {...register('name', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.name && <span className="text-red-500 text-xs italic">Name is required.</span>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Feedback Reason</label>
        <select {...register('reason', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="Something is broken">Something is broken</option>
          <option value="Accessibility">Accessibility</option>
          <option value="Ethics">Ethics</option>
          <option value="Positive">Positive</option>
          <option value="Other">Other</option>
        </select>
        {errors.reason && <span className="text-red-500 text-xs italic">Feedback reason is required.</span>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Message</label>
        <textarea {...register('message', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.message && <span className="text-red-500 text-xs italic">Message is required.</span>}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-lg font-bold mb-2">Email</label>
        <input {...register('email', { required: true })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary" />
        {errors.email && <span className="text-red-500 text-xs italic">Email is required.</span>}
      </div>
      <div className="flex items-center justify-between">
        <button type="submit" className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold hover:bg-orange py-2 px-4 rounded focus:outline-none focus:shadow-outline transition">
          Submit Feedback
        </button>
        <button type="button" onClick={onClose} className="bg-red-500 text-white text-2xl font-bold hover:bg-red-700 py-2 px-4 rounded focus:outline-none focus:shadow-outline transition">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default FeedbackForm;
