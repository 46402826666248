// src/pages/OpenIdeasFund.js
import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import Modal from '../components/Modal';
import IdeaForm from '../components/IdeaForm';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const OpenIdeasFund = () => {
  const [ideas, setIdeas] = useState([]);
  const [voted, setVoted] = useState(false);
  const [totalVotes, setTotalVotes] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ideasRef = useRef(null);

  useEffect(() => {
    const fetchIdeas = async () => {
      const startDate = new Date('2024-07-01'); // Start date
      const endDate = new Date('2024-08-23'); // End date

      let { data: ideas, error } = await supabase
        .from('ideas')
        .select('*')
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString());

      if (error) console.error('Error fetching ideas:', error);
      else setIdeas(ideas);
    };

    const fetchTotalVotes = async () => {
      let { data, error } = await supabase
        .from('ideas')
        .select('votes');

      if (error) console.error('Error fetching total votes:', error);
      else {
        const total = data.reduce((acc, idea) => acc + idea.votes, 0);
        setTotalVotes(total);
      }
    };

    fetchIdeas();
    fetchTotalVotes();

    // Check if user has voted in this session
    const hasVoted = localStorage.getItem('hasVoted');
    if (hasVoted) {
      setVoted(true);
    }
  }, []);

  const handleVote = async (ideaId) => {
    if (voted) {
      alert('You have already voted in this session.');
      return;
    }

    try {
      // Increment the vote count using RPC
      const { error } = await supabase.rpc('increment_vote', { idea_id: ideaId });

      if (error) {
        console.error('Error voting:', error);
        return;
      }

      // Update the local state
      setIdeas((prevIdeas) =>
        prevIdeas.map((idea) =>
          idea.id === ideaId ? { ...idea, votes: idea.votes + 1 } : idea
        )
      );

      // Increment total votes
      setTotalVotes(totalVotes + 1);

      // Set voted flag in local storage
      localStorage.setItem('hasVoted', 'true');
      setVoted(true);
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  const scrollToIdeas = () => {
    if (ideasRef.current) {
      ideasRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderTags = (tags) => {
    try {
      const parsedTags = JSON.parse(tags);
      if (Array.isArray(parsedTags)) {
        return parsedTags.map((tag, index) => {
          const colorClass = colorMapping[tag] || defaultColors[index % defaultColors.length];
          return (
            <span key={index} className={`px-4 py-2 rounded-full shadow-sm ${colorClass}`}>
              {tag}
            </span>
          );
        });
      }
    } catch (e) {
      // Handle single value if not an array
      const colorClass = colorMapping[tags] || defaultColors[0];
      return <span className={`px-4 py-2 rounded-full shadow-sm ${colorClass}`}>{tags}</span>;
    }
    return null;
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-3xl font-bold">Open Ideas Fund</h1>
      </div>
      <div className="mb-8 p-4 bg-white shadow-md rounded-lg">
        <p className="text-lg mb-4">Welcome to the Open Ideas Fund.</p>
        <p className="mb-4">
          Ideas are formed and built by experiences and conversations and are better when they are shared, openly in a way that allows this building. Sometimes maybe we hold onto ideas too tightly. I’m always conscious that you also need time and space to think, and that can be hard to find and resource in busy times.
        </p>
        <p className="mb-4">
          The Open Ideas Fund will give one person or organisation £500 to hopefully help you find that time. Any idea submitted between 23rd July and 23rd August 2024 will be eligible and listed on this page. Anyone can vote for the idea they like best. The idea with the most votes wins the £500. My money, your decision.
        </p>
        <p className="mb-4">
          We won't display votes numbers for ideas. And users can only vote once.
        </p>
        <p className="mb-4">
          Your idea should have a social good purpose. Your idea will be displayed openly, whether you win or not. There are no other requirements other than giving us your email to contact you should you win. You don't need tell us what you did, although that would be nice obviously.
        </p>
        <p className="mb-4">
          Click the 'contribute' button below to add an idea. Or click the view ideas or scroll down to view the ideas and vote.
        </p>
        <p className="text-xl font-bold">Everyone owns the great ideas.</p>
        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-xl font-bold py-2 px-4 rounded hover:bg-pink-700 focus:outline-none focus:shadow-outline transition"
          >
            Contribute
          </button>
          <button
            onClick={scrollToIdeas}
            className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-xl font-bold py-2 px-4 rounded hover:bg-pink-700 focus:outline-none focus:shadow-outline transition"
          >
            View Ideas
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold py-4 mb-6 rounded-lg shadow-md">
        Total Votes: {totalVotes}
      </div>
      <div ref={ideasRef} className="space-y-6">
        {ideas.map((idea) => (
          <div key={idea.id} className="p-4 bg-white shadow-md rounded-lg">
             <h1 className="bg-pink-900 rounded-lg font-bold text-xl mb-2 text-white pl-2 pb-4 pt-4">{idea.summary}</h1>
            {idea.details && (
              <>
                <h2 className="text-xl font-bold mb-2">The details</h2>
                <p className="text-gray-700 text-base mb-4">{idea.details}</p>
              </>
            )}
            {idea.problem && (
              <>
                <h2 className="text-xl font-bold mb-2">The problem</h2>
                <p className="text-gray-700 text-base mb-4">{idea.problem}</p>
              </>
            )}
            {idea.challenge && (
              <>
                <h2 className="text-xl font-bold mb-2">The challenge</h2>
                <p className="text-gray-700 text-base mb-4">{idea.challenge}</p>
              </>
            )}
            {idea.benefits && (
              <>
                <h2 className="text-xl font-bold mb-2">The benefits</h2>
                <p className="text-gray-700 text-base mb-4">{idea.benefits}</p>
              </>
            )}
            {idea.how && (
              <>
                <h2 className="text-xl font-bold mb-2">How could it work?</h2>
                <p className="text-gray-700 text-base mb-4">{idea.how}</p>
              </>
            )}
            {idea.stopping && (
              <>
                <h2 className="text-xl font-bold mb-2">Whats stopping you from turning idea into action?</h2>
                <p className="text-gray-700 text-base mb-4">{idea.stopping}</p>
              </>
            )}
            <div className="flex flex-wrap gap-2 mb-8 py-4">
              {renderTags(idea.theme)}
              {idea.status && <span className={`px-4 py-2 rounded-full shadow-sm ${colorMapping[idea.status]}`}>{idea.status}</span>}
              {renderTags(idea.looking_for)}
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={() => handleVote(idea.id)}
                className={`px-4 py-2 font-bold text-white rounded ${
                  voted ? 'bg-gray-500 cursor-not-allowed' : 'bg-pink-800 hover:bg-pink-700'
                }`}
                disabled={voted}
              >
                Vote
              </button>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <IdeaForm />
      </Modal>
    </div>
  );
};

export default OpenIdeasFund;
