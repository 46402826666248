// src/pages/IdeaDetailsPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ResponseForm from '../components/ResponseForm';
import ResponseList from '../components/ResponseList';
import Modal from '../components/Modal';
import ContactForm from '../components/ContactForm';
import { FaLinkedin, FaLink, FaEnvelope } from 'react-icons/fa';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const IdeaDetailsPage = () => {
  const { id } = useParams();
  const [idea, setIdea] = useState(null);
  const [responses, setResponses] = useState([]);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  useEffect(() => {
    const fetchIdea = async () => {
      let { data: idea, error } = await supabase.from('ideas').select('*').eq('id', id).single();
      if (error) console.error('Error fetching idea:', error);
      else setIdea(idea);
    };

    const fetchResponses = async () => {
      let { data: responses, error } = await supabase.from('responses').select('*').eq('idea_id', id);
      if (error) console.error('Error fetching responses:', error);
      else setResponses(responses);
    };

    fetchIdea();
    fetchResponses();
  }, [id]);

  if (!idea) return <div>Loading...</div>;

  const currentUrl = window.location.href;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    alert('Link copied to clipboard');
  };

  const renderTags = (tags) => {
    try {
      const parsedTags = JSON.parse(tags);
      if (Array.isArray(parsedTags)) {
        return parsedTags.map((tag, index) => {
          const colorClass = colorMapping[tag] || defaultColors[index % defaultColors.length];
          return (
            <span key={index} className={`px-4 py-2 rounded-xl shadow-sm ${colorClass}`} aria-label={`Tag: ${tag}`}>{tag}</span>
          );
        });
      }
    } catch (e) {
      // Handle single value if not an array
      const colorClass = colorMapping[tags] || defaultColors[0];
      return <span className={`px-4 py-2 rounded-xl shadow-sm ${colorClass}`} aria-label={`Tag: ${tags}`}>{tags}</span>;
    }
    return null;
  };

  return (
    <div className="container mx-auto">
      <div className="shadow-md shadow-pink-100 rounded pt-6">
        <h1 className="bg-pink-900 rounded-lg font-bold text-4xl mb-2 text-white pl-2 pb-4 pt-4">{idea.summary}</h1>
        <div className="flex items-center text-gray-700 mb-4 pl-4">
          <i className="fas fa-user mr-2" aria-hidden="true"></i> {idea.person && <span className="mr-4">{idea.person}</span>}
          <i className="fas fa-calendar-alt mr-2" aria-hidden="true"></i> <span>{new Date(idea.created_at).toLocaleDateString()}</span>
        </div>
        <div className="flex items-center mb-4 pl-4">
          <span className="mr-4">SHARE:</span>
          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="text-blue-700 text-2xl mr-2" aria-label="Share on LinkedIn">
            <FaLinkedin />
          </a>
          <button onClick={handleCopyLink} className="text-gray-700 text-2xl mr-2" aria-label="Copy link">
            <FaLink />
          </button>
          <a href={`mailto:?subject=Check out this idea&body=Check out this idea I found: ${currentUrl}`} className="text-green-600 text-2xl" aria-label="Share via email">
            <FaEnvelope />
          </a>
        </div>
        
        <div className="text-slate-700 mb-8 pl-4">
          {idea.problem && (
            <>
              <h2 className="text-2xl font-bold mb-2">The problem</h2>
              <p className="text-gray-700 text-base mb-4">{idea.problem}</p>
            </>
          )}
          {idea.challenge && (
            <>
              <h2 className="text-2xl font-bold mb-2">The challenge</h2>
              <p className="text-gray-700 text-base mb-4">{idea.challenge}</p>
            </>
          )}
          {idea.details && (
            <>
              <h2 className="text-2xl font-bold mb-2">The idea</h2>
              <p className="text-gray-700 text-base mb-4">{idea.details}</p>
            </>
          )}
          {idea.benefits && (
            <>
              <h2 className="text-2xl font-bold mb-2">The benefits</h2>
              <p className="text-gray-700 text-base mb-4">{idea.benefits}</p>
            </>
          )}
          {idea.how && (
            <>
              <h2 className="text-2xl font-bold mb-2">How could it work?</h2>
              <p className="text-gray-700 text-base mb-4">{idea.how}</p>
            </>
          )}
          {idea.stopping && (
            <>
              <h2 className="text-2xl font-bold mb-2">Whats stopping you from turning idea into action?</h2>
              <p className="text-gray-700 text-base mb-4">{idea.stopping}</p>
            </>
          )}
        </div>
        
        <div className="flex flex-wrap gap-2 mb-8 py-4">
          {renderTags(idea.theme)}
          {idea.status && <span className={`px-4 py-2 rounded-xl shadow-sm ${colorMapping[idea.status]}`} aria-label={`Status: ${idea.status}`}>{idea.status}</span>}
          {renderTags(idea.looking_for)}
        </div>
      </div>

      {idea.contact && (
        <>
          <button
            onClick={() => setIsResponseModalOpen(true)}
            className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold hover:bg-orange py-2 px-4 rounded focus:outline-none focus:shadow-outline transition"
            aria-label="Contact person"
          >
            Contact
          </button>
          <button
            onClick={() => setIsContactModalOpen(true)}
            className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold hover:bg-orange py-2 px-4 rounded focus:outline-none focus:shadow-outline transition ml-4"
            aria-label="Respond to idea"
          >
            Respond
          </button>
        </>
      )}

      {!idea.contact && (
        <button
          onClick={() => setIsContactModalOpen(true)}
          className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold hover:bg-orange py-2 px-4 rounded focus:outline-none focus:shadow-outline transition"
          aria-label="Respond to idea"
        >
          Respond
        </button>
      )}

      <Modal isOpen={isResponseModalOpen} onClose={() => setIsResponseModalOpen(false)}>
        <ContactForm ideaId={idea.id} />
      </Modal>

      <Modal isOpen={isContactModalOpen} onClose={() => setIsContactModalOpen(false)}>
        <ResponseForm ideaId={idea.id} />
      </Modal>

      {responses.length > 0 && (
        <>
          <h2 className="text-3xl font-bold mt-8 mb-4">Responses to this Idea</h2>
          <ResponseList ideaId={idea.id} />
        </>
      )}
    </div>
  );
};

export default IdeaDetailsPage;
