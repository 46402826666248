// src/components/CustomSelect.js
import React from 'react';
import { useController } from 'react-hook-form';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const options = [
  { value: 'Interested in collaborating on the idea', label: 'Collaborate' },
  { value: 'Interested in funding the idea', label: 'Funding' },
  { value: 'Actively working on the idea or similar idea', label: 'Working on similar' },
  { value: "Just think it’s a great idea", label: 'Great Idea' },
];

const CustomSelect = ({ control, name, rules }) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  return (
    <div>
      <select {...field} className="hidden">
        <option value="">Select an option</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="flex flex-col sm:flex-row sm:space-x-2 p-2 space-y-2 sm:space-y-0">
        {options.map((option, index) => {
          const colorClass = colorMapping[option.value] || defaultColors[index % defaultColors.length];
          return (
            <button
              key={option.value}
              type="button"
              className={`px-4 py-2 rounded-xl shadow-sm ${colorClass} ${field.value === option.value ? 'ring-2 ring-offset-2 ring-accent' : ''}`}
              onClick={() => field.onChange(option.value)}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      {fieldState.error && <span className="text-red-500 text-xs italic">{fieldState.error.message}</span>}
    </div>
  );
};

export default CustomSelect;

