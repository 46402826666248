// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import IdeaDetailsPage from './pages/IdeaDetailsPage';
import SubmitIdeaPage from './pages/SubmitIdeaPage';
import OpenIdeasFund from './pages/OpenIdeasFund'; 
import About from './pages/About'; 
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Analytics } from "@vercel/analytics/react"


const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
      <Analytics />
        <Header />
        <div className="flex-grow container mx-auto p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/idea/:id" element={<IdeaDetailsPage />} />
            <Route path="/submit" element={<SubmitIdeaPage />} />
            <Route path="/openideasfund" element={<OpenIdeasFund />} /> 
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
        <Footer />
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
