// src/components/ResponseForm.js
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { supabase } from '../supabaseClient';
import CustomSelect from './CustomSelect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResponseForm = ({ ideaId }) => {
  const { control, handleSubmit, register, formState: { errors } } = useForm();

  const onSubmit = async data => {
    const { error } = await supabase.from('responses').insert([
      {
        idea_id: ideaId,
        interest: data.interest,
        message: data.message,
        name: data.name,
        organisation: data.organisation,
        response_type: 'list'
      },
    ]);

    if (error) {
      console.error('Error submitting response:', error);
      toast.error('There was an error submitting your response. Please try again.');
    } else {
      toast.success('Response submitted successfully!');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto  shadow-md rounded border shadow-pink-100">
      <div className="">
        <h1 className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold py-4 pl-4 shadow-md">Repond to this idea</h1>
        <label className="block text-gray-700 text-lg font-bold mb-1 pt-2 pl-2">Your Interest</label>
        <Controller
          name="interest"
          control={control}
          rules={{ required: 'Interest is required' }}
          render={({ field }) => <CustomSelect {...field} control={control} />}
        />
        {errors.interest && <span className="text-red-500 text-xs italic">{errors.interest.message}</span>}
      </div>

      <div className="mb-4 pt-8 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Name</label>
        <input {...register('name', { required: 'Contact details are required' })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
        {errors.name && <span className="text-red-500 text-xs italic">{errors.name.message}</span>}
      </div>

      <div className="mb-4 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Organisation</label>
        <input {...register('organisation', { required: 'Contact details are required' })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
        {errors.organisation && <span className="text-red-500 text-xs italic">{errors.organisation.message}</span>}
      </div>


      <div className="mb-4 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Message</label>
        <textarea {...register('message')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
      </div>

      
      <div className="flex items-center justify-between pl-2">
        <button type="submit" className="bg-gradient-to-r from-gray-900 to-pink-800 hover:bg-orange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition">
          Submit Response
        </button>
      </div>
    </form>
  );
};

export default ResponseForm;

