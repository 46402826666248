import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { supabase } from '../supabaseClient';
import CustomSelect from './CustomSelect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = ({ ideaId }) => {
  const { control, handleSubmit, register, formState: { errors } } = useForm();
  const [userEmail, setUserEmail] = useState(null);
  const [ideaSummary, setIdeaSummary] = useState('');

  useEffect(() => {
    const fetchIdea = async () => {
      const { data: idea, error: ideaError } = await supabase
        .from('ideas')
        .select('summary, user_id')
        .eq('id', ideaId)
        .single();

      if (ideaError) {
        console.error('Error fetching idea:', ideaError);
        return;
      }

      setIdeaSummary(idea.summary);

      const userId = idea.user_id;
      const { data: user, error: userError } = await supabase
        .from('users')
        .select('email')
        .eq('id', userId)
        .single();

      if (userError) {
        console.error('Error fetching user:', userError);
        return;
      }

      setUserEmail(user.email);
    };

    fetchIdea();
  }, [ideaId]);

  const onSubmit = async data => {
    if (!userEmail) {
      toast.error('User email not found. Cannot send response.');
      return;
    }

    // Store the response in Supabase
    const { error: supabaseError } = await supabase.from('responses').insert([
      {
        idea_id: ideaId,
        interest: data.interest,
        message: data.message,
        name: data.name,
        organisation: data.organisation,
        response_type: 'direct',
        email: data.email
      },
    ]);

    if (supabaseError) {
      console.error('Error submitting response:', supabaseError);
      toast.error('There was an error submitting your response. Please try again.');
      return;
    }

    // Send an email using SendGrid
    const emailData = {
      to: userEmail, // Send the email to the user's email
      subject: 'New Response to Your Idea on Open Ideas',
      templateId: 'd-4e8150a775c14347af90843ea9dd3c01', // Your SendGrid contact template ID
      dynamicTemplateData: {
        ideaSummary: ideaSummary,
        name: data.name,
        organisation: data.organisation,
        interest: data.interest,
        message: data.message,
        email: data.email,
      },
    };

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error('Failed to send email.');
      }

      toast.success('Response submitted and email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('There was an error sending your email. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto shadow-md rounded border shadow-pink-100">
      <div className="">
        <h1 className="bg-gradient-to-r from-gray-900 to-pink-800 text-white text-2xl font-bold py-4 pl-4 shadow-md">Contact person directly about this idea</h1>
        <label className="block text-gray-700 text-lg font-bold mb-1 pt-2 pl-2">Your Interest</label>
        <Controller
          name="interest"
          control={control}
          rules={{ required: 'Interest is required' }}
          render={({ field }) => <CustomSelect {...field} control={control} />}
        />
        {errors.interest && <span className="text-red-500 text-xs italic">{errors.interest.message}</span>}
      </div>

      <div className="mb-4 pt-8 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Name</label>
        <input {...register('name', { required: 'A contact name is required' })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
        {errors.name && <span className="text-red-500 text-xs italic">{errors.name.message}</span>}
      </div>

      <div className="mb-4 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Organisation</label>
        <input {...register('organisation', { required: 'Contact details are required' })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
        {errors.organisation && <span className="text-red-500 text-xs italic">{errors.organisation.message}</span>}
      </div>

      <div className="mb-4 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Email</label>
        <input {...register('email', { required: 'An email is required for contact' })} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
        {errors.email && <span className="text-red-500 text-xs italic">{errors.email.message}</span>}
      </div>

      <div className="mb-4 pl-2">
        <label className="block text-gray-700 text-lg font-bold mb-2">Message</label>
        <textarea {...register('message')} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-accent" />
      </div>

      <div className="flex items-center justify-between pl-2">
        <button type="submit" className="bg-gradient-to-r from-gray-900 to-pink-800 hover:bg-orange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition">
          Submit Contact
        </button>
      </div>
    </form>
  );
};

export default ContactForm;

