// src/pages/SubmitIdeaPage.js
import React from 'react';
import IdeaForm from '../components/IdeaForm';

const SubmitIdeaPage = () => {
  return (
    <div className="container mx-auto p-4">
      
      <IdeaForm />
    </div>
  );
};

export default SubmitIdeaPage;
