// src/utils/colorMapping.js
const colorMapping = {
    Technology: 'border-dashed border-4 border-slate-600 text-orange-800',
    Health: 'border-dashed border-4 border-green-600 text-green-800',
    Education: 'border-dashed border-4 border-orange-800 text-orange-800',
    Environment: 'border-dashed border-4 border-blue-400 text-blue-800',
    Other: 'border-dashed border-4 border-red-200 text-red-800',
    Data: 'border-dashed border-4 border-purple-800 text-purple-800',
    'Just an idea': 'border-double border-4 border-pink-600 text-yellow-800',
    'Minimally tested': 'border-double border-4 border-slate-500 text-blue-800',
    Prototyped: 'border-double border-4 border-green-800 text-green-800',
    Funding: 'border-double border-4 border-red-800',
    Collaboration: 'border-double border-4 border-sky-500',
    'Just take it': 'border-double border-4 border-accent',
    'Interested in collaborating on the idea': 'border-double border-4 border-blue-200 text-blue-800',
    'Interested in funding the idea': 'border-double border-4 border-green-600 text-green-800',
    'Actively working on the idea or similar idea': 'border-double border-4 border-yellow-200 text-yellow-800',
    "Just think it’s a great idea": 'border-double border-4 border-pink-200 text-pink-800'
  };
  
  const defaultColors = [
    'border-double border-4 border-purple-200 text-purple-800',
    'border-double border-4 border-indigo-200 text-indigo-800',
    'border-double border-4 border-teal-200 text-teal-800',
    'border-double border-4 border-pink-200 text-pink-800',
    'border-double border-4 border-yellow-200 text-yellow-800'
  ];
  
  export { colorMapping, defaultColors };
  