// src/components/Filter.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const Filter = ({ filters, setFilters }) => {
  const [themeOptions, setThemeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [lookingForOptions, setLookingForOptions] = useState([]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      let { data: ideas, error } = await supabase.from('ideas').select('*');
      if (error) {
        console.error('Error fetching filter options:', error);
        return;
      }

      const parseField = (field) => {
        try {
          return JSON.parse(field);
        } catch {
          return [field];
        }
      };

      // Extract unique themes
      const themes = [
        ...new Set(
          ideas.flatMap(idea => parseField(idea.theme))
        )
      ];
      setThemeOptions(themes);

      // Extract unique statuses
      const statuses = [...new Set(ideas.map(idea => idea.status))];
      setStatusOptions(statuses);

      // Extract unique looking_for options
      const lookingForOptions = [
        ...new Set(
          ideas.flatMap(idea => parseField(idea.looking_for))
        )
      ];
      setLookingForOptions(lookingForOptions);
    };

    fetchFilterOptions();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
      <div className="mb-4 sm:mb-0">
        <label className="block text-gray-700 text-lg font-bold mb-2">Theme</label>
        <select name="theme" value={filters.theme} onChange={handleFilterChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="">All</option>
          {themeOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>

      <div className="mb-4 sm:mb-0">
        <label className="block text-gray-700 text-lg font-bold mb-2">Status</label>
        <select name="status" value={filters.status} onChange={handleFilterChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="">All</option>
          {statusOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>

      <div className="mb-4 sm:mb-0">
        <label className="block text-gray-700 text-lg font-bold mb-2">Looking For</label>
        <select name="looking_for" value={filters.looking_for} onChange={handleFilterChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-secondary">
          <option value="">All</option>
          {lookingForOptions.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filter;
