import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const IdeaCard = ({ idea }) => {
  const navigate = useNavigate();

  const renderTags = (tags) => {
    try {
      const parsedTags = JSON.parse(tags);
      if (Array.isArray(parsedTags)) {
        return parsedTags.map((tag, index) => {
          const colorClass = colorMapping[tag] || defaultColors[index % defaultColors.length];
          return (
            <span key={index} className={`px-4 py-2 rounded-xl shadow-sm ${colorClass}`} aria-label={`Tag: ${tag}`}>{tag}</span>
          );
        });
      }
    } catch (e) {
      // Handle single value if not an array
      const colorClass = colorMapping[tags] || defaultColors[0];
      return <span className={`px-4 py-2 rounded-xl shadow-sm ${colorClass}`} aria-label={`Tag: ${tags}`}>{tags}</span>;
    }
    return null;
  };

  return (
    <div 
      onClick={() => navigate(`/idea/${idea.id}`)} 
      className="w-full mx-auto rounded overflow-hidden shadow-lg pt-6 shadow-pink-50 cursor-pointer hover:bg-zinc-50" 
      role="button" 
      tabIndex={0}
      onKeyPress={(e) => { if (e.key === 'Enter') navigate(`/idea/${idea.id}`); }}
    >
      <div className="bg-pink-900 rounded-lg font-bold text-2xl mb-2 text-white pl-2">{idea.summary}</div>
      {idea.person && <p className="text-gray-700 text-lg mb-2 pl-2"><i className="fas fa-user" aria-hidden="true"></i> {idea.person}</p>}
      <p className="text-gray-700 text-lg mb-2 pl-2"><i className="fas fa-calendar-alt" aria-hidden="true"></i> {new Date(idea.created_at).toLocaleDateString()}</p>
      <p className="text-gray-700 text-lg mb-4 pl-2">{idea.details}</p>
      <div className="flex flex-wrap gap-2 py-4 pl-2">
        {renderTags(idea.theme)}
        {idea.status && <span className={`px-4 py-2 rounded-xl shadow-sm ${colorMapping[idea.status]}`} aria-label={`Status: ${idea.status}`}>{idea.status}</span>}
        {renderTags(idea.looking_for)}
      </div>
    </div>
  );
};

export default IdeaCard;
