// src/components/Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import FeedbackForm from './FeedbackForm';

const Footer = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <footer className="bg-gradient-to-r from-gray-900 to-pink-800 text-white py-4 shadow-md">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center px-4">
        <div className="flex items-center mb-4 sm:mb-0">
          <img src="logo.png" alt="Logo" className="h-12 mr-3" />
          <span className="text-lg sm:text-xl font-bold">Open Ideas</span>
        </div>
        <p className="text-center mb-4 sm:mb-0">
          Open Ideas for a better world. Join us in making a difference. Made and maintained by Data For Action
        </p>
        <nav className="flex flex-col sm:flex-row items-center">
          <button
            onClick={() => setIsFeedbackModalOpen(true)}
            className="block sm:inline-block mt-2 sm:mt-0 sm:ml-4 px-4 py-2 text-lg sm:text-xl font-bold text-[#f4f4f4] hover:text-yellow rounded transition"
          >
            Feedback
          </button>
          <Link to="/about" className="block sm:inline-block mt-2 sm:mt-0 sm:ml-4 px-4 py-2 text-lg sm:text-xl font-bold text-[#f4f4f4] hover:text-yellow rounded transition">
            About
          </Link>
        </nav>
      </div>
      <Modal isOpen={isFeedbackModalOpen} onClose={() => setIsFeedbackModalOpen(false)}>
        <FeedbackForm onClose={() => setIsFeedbackModalOpen(false)} />
      </Modal>
    </footer>
  );
};

export default Footer;
