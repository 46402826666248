import React, { useState, useEffect } from 'react';
import IdeaTable from '../components/IdeaTable';
import IdeaCard from '../components/IdeaCard';
import Filter from '../components/Filter';
import { supabase } from '../supabaseClient';
import QuoteSection from '../components/QuoteSection';

const HomePage = () => {
  const [ideas, setIdeas] = useState([]);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const [viewMode, setViewMode] = useState('cards'); // 'table' or 'cards'
  const [filters, setFilters] = useState({ theme: '', status: '', looking_for: '' });

  useEffect(() => {
    const fetchIdeas = async () => {
      let { data: ideas, error } = await supabase.from('ideas').select('*');
      if (error) console.error('Error fetching ideas:', error);
      else {
        setIdeas(ideas);
        setFilteredIdeas(ideas);
      }
    };

    fetchIdeas();
  }, []);

  useEffect(() => {
    const filterIdeas = () => {
      const { theme, status, looking_for } = filters;
      const filtered = ideas.filter(idea => {
        const themeMatch = theme ? (Array.isArray(JSON.parse(idea.theme)) ? JSON.parse(idea.theme).includes(theme) : idea.theme === theme) : true;
        const statusMatch = status ? idea.status === status : true;
        const lookingForMatch = looking_for ? (Array.isArray(JSON.parse(idea.looking_for)) ? JSON.parse(idea.looking_for).includes(looking_for) : idea.looking_for === looking_for) : true;
        return themeMatch && statusMatch && lookingForMatch;
      });
      setFilteredIdeas(filtered);
    };

    filterIdeas();
  }, [filters, ideas]);

  return (
    <div className='w-full'>
      <QuoteSection />
      <div className="container mx-auto px-4">
        <Filter filters={filters} setFilters={setFilters} />
        <div className="flex flex-col sm:flex-row justify-end mb-4">
          <div className="mb-4 sm:mb-0">
            <button 
              onClick={() => setViewMode('table')} 
              className={`px-4 py-2 ${viewMode === 'table' ? 'bg-pink-900 rounded-lg font-bold text-white p-2' : 'bg-gray-300 rounded-lg font-bold text-white p-2'} transition`} 
              aria-pressed={viewMode === 'table'}
            >
              Table View
            </button>
            <button 
              onClick={() => setViewMode('cards')} 
              className={`ml-0 sm:ml-2 mt-2 sm:mt-0 px-4 py-2 ${viewMode === 'cards' ? 'bg-pink-900 rounded-lg font-bold text-white p-2' : 'bg-gray-300 rounded-lg font-bold text-white p-2'} transition`} 
              aria-pressed={viewMode === 'cards'}
            >
              Card View
            </button>
          </div>
        </div>
        {viewMode === 'table' ? (
          <div className="overflow-x-auto">
            <IdeaTable ideas={filteredIdeas} />
          </div>
        ) : (
          <div className="space-y-6">
            {filteredIdeas.map(idea => <IdeaCard key={idea.id} idea={idea} />)}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
