// src/components/QuoteSection.js
import React from 'react';

const QuoteSection = () => {
  return (
    <section className="py-6 sm:px-8 lg:px-16 w-full mx-auto">
      <div className="w-full bg-gradient-to-r from-gray-900 to-pink-800 text-white py-20 px-4 rounded-lg shadow-md text-center">
        <div className=""></div>
        <blockquote className="relative z-10 text-3xl sm:text-4xl font-bold italic">
          "Everyone owns the great ideas"
        </blockquote>
        <p className="relative z-10 mt-2 text-2xl">A place to share ideas openly</p>
      </div>
      <div className="bg-zinc-50 text-pink-800 p-8 rounded-lg shadow-md mt-6">
        <p className="text-lg">
          We all have ideas. Sometimes we can follow them through into action. Other times something stops them from becoming reality and they just stay ideas. But what if we shared them openly? 
        </p>
        <p className="mt-4 text-lg">
          Maybe we find fellow collaborators. Maybe you inspire someone. Maybe we find someone to help fund the idea. Maybe someone else makes the idea real. 
        </p>
        <p className="mt-4 text-lg font-bold">
          Whatever happens, maybe just sharing an idea is the first step to making it a reality. You can do that here
        </p>
      </div>
    </section>
  );
};

export default QuoteSection;
