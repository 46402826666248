// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-gray-900 to-pink-800 text-white py-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center px-4">
        <h1 className="text-2xl sm:text-4xl font-bold">Open Ideas</h1>
        <div className="sm:hidden">
          <button onClick={toggleMenu} className="text-2xl focus:outline-none">
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav className={`sm:flex sm:items-center ${menuOpen ? 'block' : 'hidden'} w-full sm:w-auto`}>
          <Link to="/" className="block sm:inline-block mt-2 sm:mt-0 sm:ml-4 px-4 py-2 text-lg sm:text-2xl font-bold text-[#f4f4f4] hover:text-yellow rounded transition">
            Ideas
          </Link>
          <Link to="/submit" className="block sm:inline-block mt-2 sm:mt-0 sm:ml-4 px-4 py-2 text-lg sm:text-2xl font-bold text-[#f4f4f4] hover:text-yellow rounded transition">
            Contribute
          </Link>
          

        </nav>
      </div>
    </header>
  );
};

export default Header;
