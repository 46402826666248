// src/components/ResponseList.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { colorMapping, defaultColors } from '../utils/colorMapping';

const ResponseList = ({ ideaId }) => {
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const fetchResponses = async () => {
      let { data: responses, error } = await supabase
        .from('responses')
        .select('*')
        .eq('idea_id', ideaId)
        .eq('response_type', 'list');

      if (error) {
        console.error('Error fetching responses:', error);
      } else {
        setResponses(responses);
      }
    };

    fetchResponses();
  }, [ideaId]);

  const renderTags = (tags) => {
    const colorClass = colorMapping[tags] || defaultColors[0];
    return <span className={`px-2 py-2 rounded-xl shadow-sm ${colorClass}`}>{tags}</span>;
  };

  return (
    <div className="mt-8">
      {responses.length > 0 ? (
        <div className="space-y-6">
          {responses.map(response => (
            <div key={response.id} className="bg-zinc-50 shadow-md rounded p-6 shadow-pink-100">
              <div className="font-bold text-lg mb-2 text-primary">
                {renderTags(response.interest)}
              </div>
              <p className="text-gray-700 text-base mb-4 pt-4">{response.message}</p>
              <p className="text-gray-700 text-base mb-2"><i className="fas fa-user mr-2"></i> {response.name}</p>
              <p className="text-gray-700 text-base mb-2"><i className="fas fa-building mr-2"></i> {response.organisation}</p>
              <p className="text-gray-700 text-sm">Date: {new Date(response.created_at).toLocaleDateString()}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No responses yet.</p>
      )}
    </div>
  );
};

export default ResponseList;
